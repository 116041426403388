import React, { useEffect } from 'react';
import './MatrixAnimation.css';

const MatrixAnimation = () => {
  useEffect(() => {
    const canvas = document.getElementById('matrix');
    const ctx = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const letters = Array(256).join(1).split('');
    const draw = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, .05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'rgba(0, 255, 0, 0.7)'; // Lighter green color
      ctx.font = '20px monospace'; // Larger font size
      letters.map((y_pos, index) => {
        const text = String.fromCharCode(65 + Math.random() * 57); // Letters and numbers
        const x_pos = index * 20;
        ctx.fillText(text, x_pos, y_pos);
        letters[index] = y_pos > 758 + Math.random() * 1e4 ? 0 : y_pos + 20;
      });
    };
    setInterval(draw, 33);
  }, []);

  return (
    <div className="matrix-container">
      <canvas id="matrix"></canvas>
    <div className="coming-soon-text">Coming Soon</div>
	  </div>
  );
};

export default MatrixAnimation;

