import React from 'react';
import './App.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { AppBar, Toolbar, Typography, Container, Grid, Paper, Box, TextField, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import MatrixAnimation from './components/MatrixAnimation';

function App() {
  return (
    <div className="App">
      <MatrixAnimation />
    </div>
  );
}

export default App;
/*
// Register the required components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const dashboardData = {
  mainNumber: 9255,
  subData: [3.25, 2.25, 3.55, 5.52],
  chartData1: {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [{
      label: 'Data Set 1',
      backgroundColor: '#00aaff',
      borderColor: '#007acc',
      borderWidth: 1,
      hoverBackgroundColor: '#007acc',
      hoverBorderColor: '#005f99',
      data: [40, 30, 50, 70, 60, 80],
    }]
  },
  chartData2: {
    labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [{
      label: 'Data Set 2',
      backgroundColor: '#00aaff',
      borderColor: '#007acc',
      borderWidth: 1,
      hoverBackgroundColor: '#007acc',
      hoverBorderColor: '#005f99',
      data: [60, 80, 90, 70, 50, 40],
    }]
  },
  newsContent: "Breaking News: Significant event happening in the world. More details will follow...",
};

function App() {
  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            DASHBOARD
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg" style={{ marginTop: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h3" component="h1">
                {dashboardData.mainNumber.toLocaleString()}
              </Typography>
              <Box display="flex" justifyContent="space-around" marginTop="20px">
                {dashboardData.subData.map((data, index) => (
                  <Paper key={index} style={{ padding: '10px', backgroundColor: '#00aaff', color: '#fff' }}>
                    <Typography variant="h6">{data}</Typography>
                  </Paper>
                ))}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5">First Half Year</Typography>
              <Bar data={dashboardData.chartData1} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5">Second Half Year</Typography>
              <Bar data={dashboardData.chartData2} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5">News</Typography>
              <Typography>{dashboardData.newsContent}</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h5">Chat Input</Typography>
              <TextField fullWidth placeholder="Type your message" variant="outlined" />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default App;
*/
